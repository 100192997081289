<template>
  <form v-on:submit.prevent="onSubmit">
    <div class="modal-header">
      <h4 class="modal-title">Manage Advertisement</h4>
    </div>

    <div class="modal-body">
      <div class="form-group">
        <label for="name">Image Name <span class="red-dot ml-3"></span></label>
        <input id="name" name="name" type="text" class="form-control" :class="{ 'is-invalid': errors.has('name') }"
          autocomplete="off" placeholder="Image Name" v-model="advertisement.name" v-validate="'required'" data-vv-as="Image Name" />
        <div class="invalid-feedback">The Image Name field is required.</div>
      </div>

      <div class="form-group">
        <label for="imgAltText">Image Alt Text <span class="red-dot ml-3"></span></label>
        <input id="imgAltText" name="imgAltText" type="text" class="form-control" :class="{ 'is-invalid': errors.has('imgAltText') }"
          autocomplete="off" placeholder="Image Alt Text" v-model="advertisement.imgAltText" v-validate="'required'" data-vv-as="Image Alt Text" />
        <div class="invalid-feedback">The Image Alt Text field is required.</div>
      </div>

      <div class="form-group">
        <label for="linkToUrl" class="mb-0">Link Url <span class="red-dot ml-3"></span></label>
        <small id="passwordHelpBlock" class="form-text text-muted mt-0 mb-1">
          The provided URL requires a protocol (i.e. http or https) at the beginning of the address
        </small>
        <input id="linkToUrl" name="linkToUrl" type="text" class="form-control" :class="{ 'is-invalid': errors.has('linkToUrl') }"
          autocomplete="off" placeholder="Link Url" v-model="advertisement.linkToUrl" v-validate="{ required: true, url: { require_protocol: true }}" data-vv-as="Link Url" />
        <div class="invalid-feedback">{{ errors.first('linkToUrl') }}</div>
      </div>

      <div class="form-group">
        <label for="linkTitleText">Link Title Text <span class="red-dot ml-3"></span></label>
        <input id="linkTitleText" name="linkTitleText" type="text" class="form-control" :class="{ 'is-invalid': errors.has('linkTitleText') }"
          autocomplete="off" placeholder="Link Title Text" v-model="advertisement.linkTitleText" v-validate="'required'" data-vv-as="Link Title Text" />
        <div class="invalid-feedback">The Link Title Text field is required.</div>
      </div>

      <div class="form-group" v-if="!isEdit">
        <label for="exampleFormControlFile1">Upload File</label>
        <div class="custom-file">
          <input type="file" id="validatedCustomFile" name="validatedCustomFile" class="custom-file-input" :class="{ 'is-invalid': errors.has('validatedCustomFile') }"
            accept="image/*" v-validate="'required'" @change="uploadFile">
          <label class="custom-file-label" for="validatedCustomFile">
            <span class="custom-file-label-text" ref="uploadLabel">Choose file...</span>
          </label>
          <div class="invalid-feedback">Example invalid custom file feedback</div>
        </div>
      </div>

      <div class="form-group">
        <label class="mb-0">Apply To Group</label>
        <small class="form-text text-muted mt-0 mb-1">
          Disabled options indicate that the advertisement group has reached capacity ({{ maxAllowedAdvertisements }}).
        </small>
        <div id="shareWithZones">
          <div v-for="(group, index) in getAdvertismentGroups" :key="group.id" class="custom-control custom-checkbox">
            <input :id="'adGroup-' + index" name="adGroup" type="checkbox" class="custom-control-input" :class="{ 'is-invalid': errors.has('adGroup') }"
              :value="group.id" :readonly="isCategoryUnavailable(group)" :disabled="isCategoryUnavailable(group)" v-model="advertisement.advertisementGroups" v-validate="'required'">
            <label class="custom-control-label" :for="'adGroup-' + index">{{ group.name }}</label>
            <div class="invalid-feedback" v-if="index === getAdvertismentGroups.length - 1">Please select at least one advertisement group.</div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="mb-0">Apply To Zone <span class="red-dot ml-3"></span></label>
        <small class="form-text text-muted mt-0 mb-1">
          When no zones are selected, it is assumed available for all.
        </small>
        <div id="shareWithZones">
          <div v-for="(zone, index) in getZones" :key="zone.id" class="custom-control custom-checkbox">
            <input :id="'zone-' + index" name="zone" type="checkbox" class="custom-control-input" :value="zone.id" v-model="advertisement.zones">
            <label class="custom-control-label" :for="'zone-' + index">{{ zone.name }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-success ld-ext-right" :class="{ 'running' : isSaving }" :disabled="isSaving">
        <span v-if="isSaving">Saving...</span>
        <span v-else>Save</span>
        <div class="ld ld-ring ld-spin"></div>
      </button>
      <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
    </div>
  </form>
</template>

<style scoped>
.custom-file-label-text {
  display: block;
  margin-right: 82px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: 'advertisement',
  data: function () {
    return {
      initialGroups: [],
      isEdit: false,
      isSaving: false,
      maxAllowedAdvertisements: 20,
      advertisement: {
        advertisementGroups: [],
        zones: []
      }
    }
  },
  computed: {
    ...mapGetters(["getAdvertisment", "getAdvertismentGroups", "getZones"])
  },
  mounted: function() {
    this.advertisement = { ...this.getAdvertisment };
    this.initialGroups = this.advertisement.advertisementGroups;
    this.isEdit = !!this.advertisement.id;
    this.$validator.reset();
    this.fetchZones();
  },
  methods: {
    isCategoryUnavailable (group) {
      if (this.initialGroups.includes(group.id))
        return false;

      return group.adCount >= this.maxAllowedAdvertisements;
    },
    uploadFile(e) {
      let splittedValue = e.target.value.split('\\');
      let filename = splittedValue[splittedValue.length - 1];
      this.$refs.uploadLabel.innerHTML = filename;
      this.advertisement.image = e.target.files[0];
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      this.isSaving = true;

      this.saveAdvertisement({ data: this.advertisement, isEdit: this.isEdit })
        .then(() => {
          this.isSaving = false;
          Toast.success(this, "Successfully saved!");
          this.closeModal();
        })
        .catch(() => {
          this.isSaving = false;
          Toast.danger(this, "Oops! There was an error.");
        });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-advertisement");
    },
    ...mapActions(["fetchZones", "saveAdvertisement"])
  }
}
</script>
